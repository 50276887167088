import React from 'react';
import "./common.css";
import logo from "../../Assets/logo/logo.png";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faFacebookF } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  const navigate = useNavigate();
  const year = new Date().getFullYear();
  
  return (
    <div className='footercontainer'>
      <div className='contactIcons'>
        <img className='flogo' src={logo} alt="logo" />
        <div className='cicons'>
          <a href="tel:+2347054454910" className="contact-item"><FontAwesomeIcon icon={faPhone} /></a>
          <a href="https://wa.me/2349030545602" target="_blank" rel="noopener noreferrer" className="contact-icon">        
            <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: '20px', color: '#25D366' }} />
          </a>         
          <a href="https://www.facebook.com/people/Sapphire-blue-Software-Empire-Limited/100087989894112/" className="contact-icon" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebookF} style={{ fontSize: '20px', color: '#1877F2' }} /></a>          
          <a href="mailto:sapphirebluesoft@gmail.com" className="contact-item"><FontAwesomeIcon icon={faEnvelope} /></a>
          <a href="https://www.google.com/maps?q=Plot+12,+Humponu+Wusu,+Lekki" target="_blank" rel="noopener noreferrer" className="contact-item"><FontAwesomeIcon icon={faMapMarkerAlt} /></a>
        </div>
      </div>
      <div className='line'></div>
      <div className='contactIcons'>
        <div className='cicons'>
          <p onClick={() => navigate("/terms")}><span>Terms of Service</span></p>
          <p onClick={() => navigate("/privacy-policy")}><span>Privacy Policy</span></p>
          <p onClick={() => navigate("/faq")}><span>F.A.Q</span></p> {/* Fixed the typo */}
        </div>
        <p><span>Copy Right &copy; {year} Sapphire-blue Software Empire Limited. All Rights Reserved</span></p> {/* Fixed "Rigt" */}
      </div>
    </div>
  );
}

export default Footer;
