import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import { useNavigate } from "react-router-dom"; 
import { phone_regex, email_regex } from "./expressions";
import { FaTimes } from 'react-icons/fa'; // Importing the close (X) icon

import "./form.css";

const Form = (props) => {
  const [input, setInput] = useState({
    fName: "",
    lName: "",
    Email: "",
    phone: "",
    message: ""
  });
  const [agreeToCommunications, setAgreeToCommunications] = useState(false); 
  const navigate = useNavigate(); 

  // Function to handle form submission
  const handleClick = async () => {
    let currentDate = new Date().toJSON().slice(0, 20);
    try {
      if (!input.fName) throw new Error('Kindly provide your legal first name');
      if (!input.lName) throw new Error('Kindly provide your legal last name');
      if (!phone_regex.test(input.phone)) throw new Error('A valid phone number is required');
      if (!email_regex.test(input.Email)) throw new Error('A valid email address is required');
      if (!input.message) throw new Error('Kindly include a message on what you want to do');
      if (!agreeToCommunications) throw new Error('Please agree to receive communications.');

      const response = await fetch("https://v1.nocodeapi.com/sapphire/google_sheets/kYNCgurhDDweTbuX?tabId=Sheet1", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify([[input.fName, input.lName, input.phone, input.Email, input.message, currentDate]])
      });

      await response.json();
      toast.success("Your message has been submitted successfully!", { autoClose: 7000 });
      navigate("/pick-date"); 

    } catch (error) {
      toast.error(error.message === "Failed to fetch" ? "Kindly check your network connection and try again!" : error.message, { autoClose: 7000 });
      console.log("Error ===>", error);
    }
  };

  return (
    <>
      <form className="contact-form">
        {/* Cancel button as an X icon in top-right corner */}
        <button type="button" className="cancel-button" onClick={() => props.closeForm()}>
          <FaTimes />
        </button>

        <div className="form-title-container">
          <h3>Let’s get some info<span className="bluedot"></span></h3>
          <div className="blue-underline"></div>
        </div>

        <div className="form-group">
          <label htmlFor="fName">First Name</label>
          <input
            id="fName"
            className="input-field"
            onChange={e => setInput({ ...input, fName: e.target.value })}
            placeholder="First Name"
            type="text"
          />
          
          <label htmlFor="lName">Last Name</label>
          <input
            id="lName"
            className="input-field"
            onChange={e => setInput({ ...input, lName: e.target.value })}
            placeholder="Last Name"
            type="text"
          />
          
          <label htmlFor="phone">Phone</label>
          <input
            id="phone"
            className="input-field"
            onChange={e => setInput({ ...input, phone: e.target.value })}
            placeholder="Phone"
            type="tel"
          />
          
          <label htmlFor="email">Email</label>
          <input
            id="email"
            className="input-field"
            onChange={e => setInput({ ...input, Email: e.target.value })}
            placeholder="Email"
            type="email"
          />
        </div>

        {/* Checkbox Section */}
        <div className="checkbox-section">
          <p className="agreement">
            Sapphire-blue Software Empire is committed to protecting and respecting your privacy, and we’ll only use your contact information to administer your account and to provide the products and services you requested from us. 
            From time to time, we would like to contact you about additional products and services, as well as Naked-created content that may be of interest to you.
          </p>
          <label>
            <input
              type="checkbox"
              checked={agreeToCommunications}
              onChange={() => setAgreeToCommunications(!agreeToCommunications)}
            />{" "}
            I agree to receive communications from Sapphire-blue Software Empire Limited.
          </label>
          <p className="agreement">
            For more information on how and why we are committed to protecting and respecting your privacy, please review our <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
          </p>
        </div>
          <div onClick={handleClick} className="col"> <a className="next-button" href="">Next</a> </div>
      </form>

      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} />
    </>
  );
};

export default Form;
